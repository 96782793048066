import React from 'react'
import { graphql } from 'gatsby'
import { ActionBarSticky } from '@roar/components'
import { mapper } from '../common/utils'

import LinkWrapper from './LinkWrapper'
import styled from 'styled-components'

const ACTIONBAR_PROPS_MAP = {
  headline: ['headline'],
  links: ['links'],

  style: ({ style = {} }) => {
    if (!style) return {}
    return Object.keys(style).reduce((result, item) => {
      if (style[item]) {
        result[item] = style[item]
      }

      return result
    }, {})
  },
}
const LINK_MAP = {
  links: ['links'],
}
// Styled wrapper to control the width of LinkWrapper
const ResponsiveLinkWrapper = styled.div`
  & > a > div {
    width: -webkit-fill-available; // or use 100% for better compatibility
    text-align: center; // Center align text

    @media (min-width: 768px) {
      width: auto; // Auto width on larger screens
    }
  }

  & > a {
    width: 100%; // Ensure the button fills its container
    @media (min-width: 768px) {
      width: auto; // Auto width on larger screens
    }
  }
`

const ActionBarWrapper = (props) => {
  const linkProps = mapper(props, LINK_MAP)
  const { style, headline, renderCta, bgImage, ...rest } = mapper(props, ACTIONBAR_PROPS_MAP)

  return (
    <ActionBarSticky
      bg={style.bg ? style.bg : 'brandBeigeLight'}
      {...style}
      {...rest}
      headline={headline}
      bgImage={bgImage}
      renderCta={() => (
        <ResponsiveLinkWrapper>
          <LinkWrapper {...linkProps} colorScheme={style.colorScheme ? style.colorScheme : 'red'} />
        </ResponsiveLinkWrapper>
      )}
    />
  )
}

export default ActionBarWrapper

export const actionBar = graphql`
  fragment ActionBar on ContentfulActionBar {
    id
    contentful_id
    headline
    links {
      ...LinkFields
    }
    style {
      bg
      colorScheme
    }
  }
`
